@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .base-container {
    @apply container mx-auto px-3;
  }
  .base-grid {
    @apply grid grid-cols-12 gap-4;
  }
  .base-button {
    @apply bg-main-orange px-11 py-3 block mx-auto;
  }
  .base-bg {
    @apply bg-center bg-cover bg-no-repeat;
  }
  .slider-title {
    @apply font-title text-white text-center text-3xl md:text-6xl md:leading-tight;
  }
  .slider-subtitle {
    @apply font-subtitle text-white uppercase tracking-[.2em] md:tracking-[.4em] text-center text-xs md:text-base pb-4;
  }
  .hero-title {
    @apply font-title text-main-blue text-3xl md:text-4xl lg:text-6xl text-center;
  }
  .hero-text {
    @apply font-text text-main-grey;
  }
  .hero-link {
    @apply font-subtitle font-medium text-main-orange underline hover:text-main-blue;
  }
  .text-link {
    @apply font-text font-medium text-main-orange underline hover:text-main-blue;
  }
  .room-cards-hover .room-price,
  .room-cards-hover .room-buttons {
    @apply hidden;
  }
  .room-cards-hover .room-content:hover .room-price,
  .room-cards-hover .room-content:hover .room-buttons,
  .room-cards-hover .room-content:hover .room-amenities {
    @apply block;
  }
  .room-cards-hover .room-content:hover {
    @apply top-0 bg-black/[0.8];
  }
  .swiper-image-carousel .swiper-slide {
    @apply min-h-full h-auto;
  }
  .swiper-rooms-card .swiper-slide,
  .rooms-grid .room-container {
    @apply min-h-[450px]  h-auto md:max-h-[450px];
  }
  .section-title {
    @apply font-title text-2xl md:text-6xl tracking-[.2em] text-main-blue uppercase;
  }
  .section-subtitle {
    @apply font-subtitle tracking-[.2em] md:tracking-[.4em] text-main-orange text-left;
  }
  .newsletter-input {
    @apply bg-transparent border-b-2 border-main-blue pb-4 placeholder:text-[#A5A5A5] placeholder:font-subtitle placeholder:uppercase text-[#A5A5A5] font-subtitle outline-none w-full;
  }
  .base-button-link {
    @apply bg-main-blue px-8 md:px-11 py-3 block mx-auto font-title text-white text-sm  tracking-[0.15em] uppercase text-center w-auto;
  }
  .nav-item-hover-blue {
    @apply font-subtitle text-white hover:text-main-blue uppercase font-normal;
  }
  .nav-item {
    @apply font-subtitle text-white hover:text-main-orange uppercase font-normal;
  }
  .nav-item .sub-menu {
    @apply hidden;
  }
  .nav-top {
    @apply hidden lg:block;
  }
  .sub-nav-items .nav-item {
    @apply text-white lg:text-main-blue lg:px-4 py-1  lg:bg-white hover:text-main-orange;
  }
  .lang > .button-open {
    @apply rotate-90;
  }
  .lang .icon-open {
    @apply invert-0;
  }
  .navbar-blue {
    @apply bg-main-blue;
  }
  .navbar-blue .nav-top-mobile {
    @apply bg-main-orange;
  }
  .navbar-blue .nav-top-mobile .nav-item * {
    @apply hover:text-main-blue;
  }

  /* Estilos para grid de ROOMS OUT */
  .room-card-content {
    @apply rounded-lg relative block overflow-hidden;
  }
  .room-card-content > img {
    @apply w-full h-full;
  }
  .room-card-info{
    @apply absolute top-0 w-full h-full flex items-end bg-gradient-to-t from-black/75 via-transparent to-transparent transition-all duration-700 delay-75
  }
  .room-card-info-container {
    @apply transition-all duration-700 delay-75;
  }
  .room-card-info:hover {
    @apply bg-black/75
  }
  .amenities-container{
    @apply col-span-12 flex h-0 opacity-0 justify-around transition-all duration-700 delay-75;
  }
  .amenities-container > div{
    @apply  opacity-0 ;
  }
  .room-card-info:hover .amenities-container >div{
    @apply opacity-100 
  }
  .room-card-info:hover .amenities-container{
    @apply  h-auto opacity-100
  }
  .room-card-info:hover .room-card-info-container{
    @apply h-full 
  }
 
  .room-card-info:hover .room-card-button-container{
    @apply lg:grid lg:gap-2
  }
  
  @media (max-width: theme("screens.lg")) {
    .nav-content > .nav-items {
      @apply overflow-auto;
    }
    .nav-item.active > .sub-menu {
      @apply border-t border-main-blue-king;
    }
    .nav-item.active > .sub-menu {
      @apply block  w-full py-8;
    }
    .nav-items > .nav-item.active > .sub-menu {
      @apply absolute  h-screen bg-main-blue z-30  top-0 p-12 bottom-0 left-0 right-0 w-full;
    }
    .nav-item {
      @apply flex flex-wrap justify-center gap-6;
    }
    .nav-item.active > .button-open {
      @apply rotate-90;
    }
    .nav-items .lang {
      @apply absolute top-6 left-6;
    }
    .nav-items .lang {
      @apply absolute top-6 left-6;
    }
    .nav-items .lang.active .sub-menu {
      @apply absolute z-10 top-[100%]  left-3   p-0  border-none h-auto;
    }
    .nav-items > .nav-item > .sub-menu > .sub-nav-items {
      @apply h-[90%]  overflow-auto pt-8;
    }
    .nav-items > .nav-item.lang.active > .sub-menu > .sub-nav-items {
      @apply h-auto w-fit  gap-y-0 overflow-hidden pt-0;
    }
    .lang.active .sub-nav-items .nav-item {
      @apply rounded-sm;
    }
    .lang.active > .button-open {
      @apply -rotate-90;
    }
    .lang.active .sub-nav-items .nav-item {
      @apply text-main-blue px-4 py-1  bg-white;
    }
    .lang.active .icon {
      @apply ml-2 block;
    }
  }
  @screen lg {
    .nav-item .sub-menu {
      @apply absolute z-10 top-[100%]  -left-5   hidden;
    }
    .nav-item:hover > .sub-menu,
    .nav-item.active:hover > .sub-menu {
      @apply block;
    }
    .nav-item .sub-nav-items {
      @apply shadow-2xl shadow-indigo-500/70   min-w-full w-max  rounded-sm border;
    }
    .nav-item > .sub-menu .sub-menu {
      @apply left-[100%] top-0 pt-0 pl-5;
    }
    .nav-items > .nav-item > .sub-menu > .sub-nav-items > .nav-item .icon {
      @apply rotate-180 absolute left-3 top-2  block;
    }
    .nav-items > .nav-item > .sub-menu .button-open,
    .nav-items .lang .button-open {
      @apply lg:block;
    }

    .nav-items > .nav-item > .sub-menu > .sub-nav-items .nav-item {
      @apply flex gap-1;
    }
  }
}
@layer utilities {
  .wedding-group{
    border-radius: 40% 40% 0% 0%;
  }
  .blog-post-home {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
  }
  .white-65-transparent-35 {
    background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 1) 65%,
      rgba(251, 251, 251, 0) 65%
    );
  }
  @screen md {
    .white-65-transparent-35 {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 65%,
        rgba(251, 251, 251, 0) 65%
      );
    }
  }
  .blog-content p{
    @apply font-text text-main-grey mb-4
  }
  .blog-content p.blockquote{
    @apply text-white font-subtitle 
  }
  .blog-content p strong{
    @apply text-xl
  }
  .mk-div h2{
    @apply text-main-grey font-text text-2xl font-semibold
  }
}
.happenings-markdown a {
  color: #F18A00; 
  text-decoration: underline; 
}
.bg-main-slider{
  background:linear-gradient(rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0) 24%)
}
/*GALLERY ROOMS */
.gallery-hotel .bottom-slideshow{
  @apply absolute bottom-0  w-11/12 md:w-auto right-3 md:static z-10
}
.gallery-hotel .slideshow-swiper{
  @apply pb-28 md:pb-10
}
.gallery-hotel .swiper-pagination{
  @apply md:hidden  bottom-0
}
.gallery-hotel .swiper-button-next,.gallery-hotel .swiper-button-prev{
  @apply hidden md:flex  
}
.gallery-hotel .swiper-pagination-bullet{
  @apply bg-main-blue w-[11px] h-[11px]
}
.slider-tabs .swiper-pagination-bullet{
  @apply bg-main-blue w-[11px] h-[11px]
}
.slider-tabs .swiper-pagination{
  @apply lg:hidden  bottom-0
}
.slider-tabs>.swiper-wrapper>.swiper-slide:not(.swiper-slide-active){
  @apply max-h-24
}
/*GALLERY ROOMS*/
/*GALLERY RESTAURANTS*/
.gallery-restaurants-in .swiper-pagination{
  @apply md:hidden
}
.gallery-restaurants-in .swiper-button-prev,.gallery-restaurants-in .swiper-button-next{
  @apply hidden md:flex
}
/*GALLERy RESTAURANTS*/
/*DatePicker CSS */
.react-datepicker__header{
  padding: 0 !important;
}
.react-datepicker__current-month{
  color: #00205C !important;
  @apply font-title bg-[#EEF4FF] py-3 uppercase
}
.react-datepicker__navigation{
  top: 11px !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #00205C !important;
}
.react-datepicker__day-names{
  @apply bg-white font-title
}
.react-datepicker__day-name{
  color: #676767 !important;
}
.react-datepicker__day.react-datepicker__day--disabled{
  color: #A5A5A5 !important;
}
.react-datepicker__day{
  color: #676767 !important;
  border-radius: 0 !important;
  @apply text-base font-title 
}
.react-datepicker__day-name, .react-datepicker__day{
  margin: 0 !important;
  width: 2rem !important;
  padding: .166rem;
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
  background-color: #00205C1a !important;
  color: #00205C !important;
}

.react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--range-start,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #00205C !important;
  color: white !important;
}
.react-datepicker__day--range-start{
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}
.react-datepicker__day--range-end{
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}
/*DatePicker CSS */

/* swiper customization */
@media (min-width: 1040px) {
  .hidden-pagination .swiper-pagination-bullets{
    display:none!important;
  }
}

/*NUMBER INPUT *
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 10px; 
}

/* scroll-bar styles */
::-webkit-scrollbar-thumb {
  background-color: #00205C; 
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px; 
}

@media (max-width: 760px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}